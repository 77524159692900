<template>
  <NotificationBase :notification :icon :compact>
    <template #subject>
      {{ t('notifications.subject.bonusForDeposit') }}
    </template>
    <i18n-t
      keypath="notifications.body.bonusForDepositProgramUsersCreated"
      tag="p"
    >
      <template #multiplier>
        <b>
          {{ multiplier }}
        </b>
      </template>
    </i18n-t>
  </NotificationBase>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
import NotificationBase from '../NotificationBase.vue'
import type { BonusForDepositNotification } from '../../../../types'

const { notification, compact = false } = defineProps<{
  notification: BonusForDepositNotification
  compact?: boolean
}>()

const { t } = useI18n()

const multiplier = computed(
  () =>
    `${new Decimal(notification.data.depositMultiplier)
      .times(100)
      .toString()}%`,
)

const { currencyIcon } = useCurrencyNotification(toRef(() => notification))
const icon = computed(() => ({
  name: 'gift-solid' as const,
  currencyIcon: currencyIcon.value,
}))
</script>
